.bodyEditor {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.editorArea {
  width: 680px;
  min-height: 100%;
  box-shadow: inset 1px 1px rgba(206, 206, 206, 0.54),
    1px 1px rgba(206, 206, 206, 0.54);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-top: 30px;
}

.blockWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.blockDraggable {
  position: absolute;
  z-index: 10;
  opacity: .95;
  pointer-events: none;
  .btnRight {
    svg {
      width: 24px;
      height: 24px;
    }
    left: -34px;
  }
  
  .btnLeft {
    right: -35px;
  }
  .btnLeft {
    svg {
      width: 24px;
      height: 24px;
    }
    right: -35px;
  }
}

.editHeader {
  top: 0;
  width: 100%;
  position: absolute;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  line-height: 30px;
  text-transform: uppercase;
  background-color: rgba(206, 206, 206, 0.54);
  color: white;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editHeader:hover {
  background-color: rgba(206, 206, 206, 0.8);
}

.editContent {
  display: flex;
  align-items: center;
  width: 100%;
}

.content {
  text-align: left;
  width: 100%;
}

.btnBlock {
  position: absolute;
  transition: all 500ms ease-out;
  svg {
    width: 1px;
    height: 1px;
  }
}

.btnRight {
  left: 0;}

.btnLeft {
  right: 0;
}

.editContent:hover .btnRight {
  svg {
    width: 24px;
    height: 24px;
  }
  left: -34px;
}

.editContent:hover .btnLeft {
  svg {
    width: 24px;
    height: 24px;
  }
  right: -35px;
}

.btnWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.btnChange:hover svg {
  color: #ed7831;
}

.btnCode:hover svg {
  color: #68c0b0;
}

.btnCopy:hover svg {
  color: #58a2d6;
}

.btnDelete:hover svg {
  color: #e75d5d;
}


.dropZone {
  position: absolute;
  width: 800px;
  height: 100%;
  div:nth-child(1) {
    width: 100%;
    height: 40%;
  }
  div:nth-child(2) {
    width: 100%;
    height: 60%;
  }
}

.placeStartSet {
  width: 100%;
  height: 300px;
  position: relative;
  color: rgba(0, 0, 0, 0.54);
}

.placeStartSet div {
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);
}

.placeSet {
  box-shadow: inset 0 -4px #3f51b5;
  width: 0%;
  height: 0px;
  position: relative;
  transition: height 300ms ease-out;
  div {
    display: none;
    position: absolute;
    right: 50%;
    bottom: 50%;
    transform: translate(50%, 50%);
  }
}

.placeSeting {
  width: 100%;
  height: 300px;
  div {
    display: block;
  }
}

.line {
  display: block;
  width: 100%;
  position: absolute;
  height: 4px;
}