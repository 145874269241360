.sidebarEditor {
  background-color: white;
  padding: 20px 20px 0;
  max-height: 100vh;
  overflow: auto;
  width: 280px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.sidebarItem {
  cursor: -webkit-grab;
  padding: 10px 20px;
  height: 60px;
  margin: 0 0 20px;
  text-align: center;
  width: 100%;
  background: #f7f7f7;
  border-radius: 0;
  box-shadow: 0px 2px 1px -1px rgba(56, 44, 44, 0.2), 0px 1px 1px 0px rgba(41, 40, 40, 0.14), 0px 1px 3px 0px rgba(51, 46, 46, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;   
  -khtml-user-select: none;    
  -moz-user-select: none;      
  -ms-user-select: none;       
  user-select: none;
}

.sidebarItemDrag {
  pointer-events: none;
  z-index: 1000;
  width: 222px;
  position: absolute;
  cursor: -webkit-grabbing;
  opacity: .7;
}
