.codeWrapper {
	max-width: 680px;
	text-align: left;
	position: relative;
    text-align: left;
    transition: all 1s ease-out;
}

.btnCodeMirr {
	position: absolute;
    z-index: 10;
    right: 0;
    top: 0;
    margin-right: 17px;
    cursor: pointer;
}

