.fc-popover {
  @media screen and (max-width: 2000px) {
    max-width: 270px;
  }
  @media screen and (max-width: 1900px) {
    max-width: 220px;
  }
  @media screen and (max-width: 1500px) {
    max-width: 200px;
  }
  @media screen and (max-width: 1400px) {
    max-width: 150px;
  }
  @media screen and (max-width: 1000px) {
    max-width: 120px;
  }
}
.fc .fc-more-popover .fc-popover-body {
  @media screen and (max-width: 1500px) {
    min-width: 100px;
  }
}
